import React from 'react'
import {useParams, Link } from 'react-router-dom'
import CheckBoxComponent from '../../components/CheckBoxComponent'
import { useMediaQuery } from 'react-responsive'
import PriceComponent from '../../components/PriceComponent'
import ModalComponent, { ModalFunctions } from '../../components/ModalComponent'
import TextBoxComponent from '../../components/TextboxComponent'
import RestaurantsService from '../../services/founder/RestaurantsServices'
import CatalogsService from '../../services/app/CatalogsService'
import Select from 'react-select';
import { Rating } from 'react-simple-star-rating'
import { RatingView } from 'react-simple-star-rating'
import FieldEditable from '../../components/FieldEditable'

import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
  } from 'react-places-autocomplete';
import { toast } from 'react-toastify'
import IsSpecialCharacters from '../../services/UltiService'
import IsSpecialCharactersNotDot from '../../services/ValidateCaracters'

const RestaurantDetails = () => {
    const model ={
        id: "",
        name: "",
        description: {
            description: "",
            phone: "",
            cheff_name: ""
        },
        menu: "",
        address: {
            id: "",
            latitude: 0,
            longitude: 0,
            address: ""
        },
        cost: {
            id: "",
            name: ""
        },
        kitchens: [],
        extra_services: [],
        photos: [],
        capacity: [],
        schedule: []
    }

    const [descriptionValidation,setDescriptionValidation]= React.useState({});
    const [directionValidation,setDirectionValidation]= React.useState(true);
    const [addresValidation,setAddresValidation]= React.useState(true);
    const [phoneValidation,setPhoneValidation]= React.useState({});
    const [chefValidation,setChefValidation]= React.useState({});

    const [restaurantId] = React.useState(useParams());
    const [restaurantInfo, setRestaurantInfo]= React.useState(model);
    const [cost,setCost]= React.useState("");
    const [costsCatalog,setCostsCatalog] = React.useState([]);
    const [extraServicesCatalog,setExtraServicesCatalog] = React.useState([]);
    const [kitchenCatalog,setKitchenCatalog] = React.useState([]);

    const [latitude,setLatitude]= React.useState(0);
    const [longitude,setLongitude]= React.useState(0);
    const [direction,setDirection]= React.useState("");

    const [cancel,setCancel] = React.useState("");
    const [address, setAddress] = React.useState('');

    const [kitchensOrigin,setKitchensOrigin] = React.useState("")
    const [rating,setRating] = React.useState(0);

    const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;


    function handleDescriptionChange(e){
        restaurantInfo.description.description = e.target.value;
        if(e.target.value == "" || e.target.value.includes('  ') || IsSpecialCharactersNotDot(e.target.value)){
            setDescriptionValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else if(e.target.value.length > 250){
            setDescriptionValidation({hasValidate:true,hasError:true,error:'Máximo 250 caracteres.'});
        }
        else if (regex.test(e.target.value)) {
            setDescriptionValidation({hasValidate:true,hasError:true,error:'Formato incorrecto.'});
        }
        else{
            setDescriptionValidation({hasValidate:true,hasError:false})
        }
    }

    // function handleDirectionChange(e){
    //     if(e.target.value == ""){
    //         setDirectionValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
    //     }
    //     else{
    //         setDirectionValidation({hasValidate:true,hasError:false});
    //         restaurantInfo.address.address = e.target.value;
    //     }
    // }

    function handlePhoneChange(e){
        const phoneRegex = /(^(\d{8,13})$)|^['+'](\d{8,13})$/;
        restaurantInfo.description.phone = e.target.value;
        if(!phoneRegex.test(e.target.value)){
            setPhoneValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else{
            setPhoneValidation({hasValidate:true,hasError:false});
        }
    }

    function handleChefChange(e){
        restaurantInfo.description.cheff_name = e.target.value;
        if(e.target.value == "" || e.target.value.includes('  ') || IsSpecialCharacters(e.target.value)){
            setChefValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else if(e.target.value.length > 30){
            setChefValidation({hasValidate:true,hasError:true,error:'Máximo 30 caracteres.'});
        }
        else if (regex.test(e.target.value)) {
            setChefValidation({hasValidate:true,hasError:true,error:'Formato incorrecto.'});
        }
        else{
            setChefValidation({hasValidate:true,hasError:false})
        }
    }

    function setRatingComponent(rating) {
        setRating(rating)
        switch (rating)
        {
            case 1:
                let one = costsCatalog.find(x => x.name === "$");
                setCost(one.encrypt_id);
            break;
            case 2:
                let two = costsCatalog.find(x => x.name === "$$");
                setCost(two.encrypt_id);
            break;
            case 3:
                let three = costsCatalog.find(x => x.name === "$$$");
                setCost(three.encrypt_id);
            break;
            case 4:
                let four = costsCatalog.find(x => x.name === "$$$$");
                setCost(four.encrypt_id);
            break;
            case 5:
                let five = costsCatalog.find(x => x.name === "$$$$$");
                setCost(five.encrypt_id);
            break;
            default:
                setCost("")
        }
    };

    function handleValet(e){
        let model = {};
            model.id = restaurantInfo.id;
        let service = extraServicesCatalog.find(x => x.name === "Valet parking");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }

    function handleMenu(e){
        let model = {};
            model.id = restaurantInfo.id;
        let service = extraServicesCatalog.find(x => x.name === "Menú vegano");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }

    function handleAccess(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Acceso para silla de ruedas");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }

    function handleMenu2(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Miembros de Aderes");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }

    function handleMenu3(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Certificado Ministerio de Turismo");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }

    function handleMenu4(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Admiten mascotas");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }
    function handleMenu5(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Terraza");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }
    function handleMenu6(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Área para fumar");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }
    function handleMenu7(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Parqueo cerca");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }
    function handleMenu8(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Salones privados");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }

    function handleMenu9(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Área de juegos para niños");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }

    function handleMenu10(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Cena el 24 de diciembre");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }

    function handleMenu11(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Cena el 31 de diciembre");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }

    function handleMenu12(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Posibilidad de reservar el restaurante completo para eventos");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }


    function handlekitchen(e, food){
        let info ={};
        let validate = true;
        info.idKitchen = food;
        if(e.target.checked)
        {
            info.status = 1;
        }
        else
        {
            info.status = 0;
            let temp = restaurantInfo.kitchens.filter(x => x.encrypt_id !== food);
            validate = temp.length === 0 ? false : true;
        }
        if (validate) {
            putKitchen(info)
        }
        else{
            error("Es necesario seleccionar al menos una opción");
        }
    }

    function getCheckedKitchen(kitchenId) {
        let data = restaurantInfo.kitchens.find(x => x.encrypt_id === kitchenId);
        if(data != undefined){
            return data.status == 1 ? true : false;
        }else{
            return false;
        }
    }

    function getExtraCatalog(){
        CatalogsService.getExtraService().then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setExtraServicesCatalog(r);
                }
            }
        });
    }

    function getCostsCatalog(){
        CatalogsService.getCost().then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setCostsCatalog(r);
                }
            }
        });
    }

    function getKitchensCatalog(){
        CatalogsService.getKitchen().then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setKitchenCatalog(r.map((k) => {return {value: k.encrypt_id, label: k.name}}));
                }
            }
        });
    }

    function getKitchenOrigin(kitchens){
        let temp = "";
        kitchens.filter(x => x.status === 1).map((k) => { temp += k.name+',' });
        setKitchensOrigin(temp)
    }

    function getRestaurantInfo(){
        RestaurantsService.getRestaurant(restaurantId.id).then((r) => {
            if(r != null)
            {
                setRestaurantInfo(r);
                getKitchenOrigin(r.kitchens);
                setAddress(r.address.address);
                setRating(r.cost.name.length);
            }
            else
            {

            }
        });
    }

    function getCheckedExS(check, data){
        if(data.length != 0)
        {
            let extra = data.find(x => x.name == check);
            if(extra != undefined){
                return extra.status === 1 ? true : false;
            }
            else
            {
                return false
            }
        }
        else
        {
            return false
        }
    }

    function putDescription(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(!descriptionValidation.hasError && descriptionValidation.hasValidate != undefined){
            let model = {};
            model.id = restaurantId.id;
            model.description = restaurantInfo.description.description;
            RestaurantsService.putDescription(model).then((r) => {
                r.success === 1 ? 
                notify()
                :
                error()
                setDescriptionValidation({});
                ModalFunctions.HideModal('modalDescription'); 
            });
        }
    }

    function putDirection(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(longitude != 0 && latitude != 0){
            let model = {};
            model.id = restaurantId.id;
            model.address = address;
            model.latitude = latitude;
            model.longitude = longitude;
            RestaurantsService.putAddress(model).then((r) => {
                r.success === 1 ? 
                notify()
                :
                error()
                ModalFunctions.HideModal('modalDirection');
                getRestaurantInfo();
            });
        }

    }

    function putPhone(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(!phoneValidation.hasError && phoneValidation.hasValidate != undefined){
            let model = {};
            model.id = restaurantId.id;
            model.phone = restaurantInfo.description.phone;
            RestaurantsService.putPhone(model).then((r) => {
                r.success === 1 ? 
                notify()
                :
                error()
                setPhoneValidation({})
                ModalFunctions.HideModal('modalPhone'); 
            });
        }

    }

    function putChef(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(!chefValidation.hasError && chefValidation.hasValidate != undefined){
            let model = {};
            model.id = restaurantId.id;
            model.chef = restaurantInfo.description.cheff_name;
            RestaurantsService.putChef(model).then((r) => {
                r.success === 1 ? 
                notify()
                :
                error()
                setChefValidation({})
                ModalFunctions.HideModal('modalChef'); 
            });
        }

    }

    function putCost(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(cost != "" && cost != restaurantInfo.cost.id){
            let model = {};
            model.id = restaurantId.id;
            model.costId = cost;
            RestaurantsService.putCost(model).then((r) => {
                r.success === 1 ? 
                notify()
                :
                error()
                ModalFunctions.HideModal('modalPrice'); 
                getRestaurantInfo();
            });
        }

    }

    function putKitchen(data){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        let model = {};
        model.id = restaurantId.id;
        model.idKitchen = data.idKitchen;
        model.status = data.status;
        RestaurantsService.putKitchen(model).then((r) => {
            r.success === 1 ? 
            notify()
            :
            error()
            getRestaurantInfo();
        });
    }

    function notify() {
        toast.success('Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

      function error(message) {
        toast.error(message != undefined ? message : 'No se pudo actualizar', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

      const handleChange = address => {
        setAddress(address);
        setDirection(address);
        if(address == "")
        {
            setLatitude(0)
            setLongitude(0)
            setAddresValidation(false);
            setDirectionValidation(true);
        }
        if(latitude == 0 && longitude == 0){
            setDirectionValidation(false);
            setAddresValidation(true);
        }
      };
     
    const handleSelect = address => {
        setAddress(address);
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {
              setLatitude(latLng.lat);
              setLongitude(latLng.lng);
              setDirection(address);
              setDirectionValidation(true);
              setAddresValidation(true);
            })
          .catch(error => console.error('Error', error));
      };
    
    // const handleChange = address => {
    //     setAddress(address);
    //   };
     
    // const handleSelect = address => {
    //     setAddress(address);
    //     geocodeByAddress(address)
    //       .then(results => getLatLng(results[0]))
    //       .then(latLng => {
    //           setLatitude(latLng.lat);
    //           setLongitude(latLng.lng);
    //           setDirection(address);
    //         })
    //       .catch(error => console.error('Error', error));
    //   };

      const handleRating = (rate) => {
        setRating(rate)
      }

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    React.useEffect(()=>{
        getExtraCatalog();
        getCostsCatalog();
        getKitchensCatalog();
        getRestaurantInfo();
    },[]);


    const desktop = () =>(
        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    RESTAURANTE
                </label>
            </div>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "31px",
                textAlign: "left",
                padding: "0 24px"
            }}>
                <Link className="tabitem selected" to={`/Restaurants/Details/${restaurantId.id}`}>DESCRIPCIÓN</Link>
                <Link className="tabitem" to={`/Restaurants/Photographs/${restaurantId.id}`}>FOTOGRAFÍAS</Link>
                <Link className="tabitem" to={`/Restaurants/Capacity/${restaurantId.id}`}>CAPACIDAD</Link>
                <Link className="tabitem" to={`/Restaurants/Menu/${restaurantId.id}`}>MENÚ</Link>
                <Link className="tabitem" to={`/Restaurants/Hours/${restaurantId.id}`}>HORARIO</Link>
            </div>
            <div style={{display: "flex", alignItems: "center", padding: "44px 40px 24px 40px"}}>
                <label style={{fontFamily:"Commuters Sans SemiBold",
                    whiteSpace:'nowrap',
                    overflow:'hidden',
                    textOverflow:'ellipsis',
                    width:"500px",
                    height:"30px",
                    fontSize:"17px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    color: "black",
                    textAlign:"initial"
                    }}>
                    {restaurantInfo.name}
                </label>
            </div>
            <div style={{display: "flex", flexDirection:'column', alignItems: "center", padding: "0px 40px 67px 40px", height:'calc(100% - 150px)', overflow:'auto'}}>
                <FieldEditable
                    text="Descripción"
                    style={{width:"100%", marginBottom:"30px"}}
                    value={restaurantInfo.description.description}
                    onClick={() => {ModalFunctions.ShowModal('modalDescription'); setCancel(restaurantInfo.description.description);}}/>
                <FieldEditable
                    text="Dirección"
                    style={{width:"100%", marginBottom:"30px"}}
                    value={restaurantInfo.address.address}
                    onClick={() => {ModalFunctions.ShowModal('modalDirection'); setCancel(restaurantInfo.address.address);}}/>
                <FieldEditable
                    text="Teléfono"
                    style={{width:"100%", marginBottom:"30px"}}
                    value={restaurantInfo.description.phone}
                    onClick={() => {ModalFunctions.ShowModal('modalPhone'); setCancel(restaurantInfo.description.phone);}}/>
                <FieldEditable
                    text="Nombre del chef"
                    style={{width:"100%", marginBottom:"30px"}}
                    value={restaurantInfo.description.cheff_name}
                    onClick={() => {ModalFunctions.ShowModal('modalChef'); setCancel(restaurantInfo.description.cheff_name);}}/>
                <FieldEditable
                    text="Tipo de comida"
                    style={{width:"100%", marginBottom:"30px"}}
                    value={kitchensOrigin}
                    onClick={() => {ModalFunctions.ShowModal('modalFood');}}/>
                <div style={{display:'block', position:'relative', width:"100%", height:"120px"}}>
                    <label style={{ fontFamily:"Roboto",
                                    fontSize:"14px",
                                    color:'gray',
                                    position:'absolute',
                                    left:'0',
                                    marginBottom:'25px'}}>
                        Rango de precio
                    </label>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'100%', marginTop:'20px', borderBottom:'1px solid #818181'}}>
                        <div style={{ height:'30px', paddingTop:'15px'}}>
                            <RatingView
                                onClick={handleRating}
                                ratingValue={rating}
                                transition
                                size={25}
                                stars={5}
                                fillColor={'black'}
                                emptyColor={'white'}
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                                </svg>
                            </RatingView>
                        </div>
                        <button className="btn btn-primary" style={{position:'relative', 
                                                                    left: 'calc(100% - 80px)', 
                                                                    bottom: 15, 
                                                                    width:"80px", 
                                                                    height:"24px", 
                                                                    fontSize:"12px", 
                                                                    textAlign:"center", 
                                                                    paddingBottom:"0px",
                                                                    paddingTop:"0px",
                                                                    paddingLeft:"0px",
                                                                    paddingRight:"0px", 
                                                                    boxShadow:"none"}}
                                                            onClick={() => {ModalFunctions.ShowModal('modalPrice'); setCancel(rating);}}>
                            EDITAR
                        </button>
                    </div>
                </div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:'101%', marginBottom:'22px', justifyContent:'flex-start', marginTop:'40px', marginBottom:'33px'}}>
                    <div style={{marginRight:'88px'}}>
                        <CheckBoxComponent checked={getCheckedExS("Valet parking", restaurantInfo.extra_services)} text="Valet parking" onChange={handleValet}/>
                    </div>
                    <div style={{marginRight:'88px'}}>
                        <CheckBoxComponent checked={getCheckedExS("Menú vegano",restaurantInfo.extra_services)} text="Menú vegano" onChange={handleMenu}/>
                    </div>
                    <div style={{marginRight:'88px'}}>
                        <CheckBoxComponent checked={getCheckedExS("Acceso para silla de ruedas",restaurantInfo.extra_services)} text="Acceso para silla de ruedas" onChange={handleAccess}/>
                    </div>

                    <div style={{marginRight:'88px'}} >
                        <CheckBoxComponent checked={getCheckedExS("Miembros de Aderes",restaurantInfo.extra_services)} text="Miembros de Aderes" onChange={handleMenu2}/>
                    </div>
                    <div>
                        <CheckBoxComponent checked={getCheckedExS("Certificado Ministerio de Turismo",restaurantInfo.extra_services)} text="Certificado Ministerio de Turismo" onChange={handleMenu3}/>
                    </div>
                    
                </div>

                <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:'100%', marginBottom:'22px', justifyContent:'flex-start', marginTop:'0px', marginBottom:'30px'}}>
                <div style={{marginRight:'88px'}} >
                <CheckBoxComponent checked={getCheckedExS("Admiten mascotas",restaurantInfo.extra_services)} text="Admiten mascotas" onChange={handleMenu4}/>
                </div>
                <div style={{marginRight:'88px'}} >
                <CheckBoxComponent checked={getCheckedExS("Terraza",restaurantInfo.extra_services)} text="Terraza" onChange={handleMenu5}/>
                </div>
                <div style={{marginRight:'88px'}} >
                <CheckBoxComponent checked={getCheckedExS("Área para fumar",restaurantInfo.extra_services)} text="Área para fumar" onChange={handleMenu6}/>
                </div>
                <div style={{marginRight:'88px'}} >
                <CheckBoxComponent checked={getCheckedExS("Parqueo cerca",restaurantInfo.extra_services)} text="Parqueo cerca" onChange={handleMenu7}/>
                </div>
                <div style={{marginRight:'88px'}} >
                <CheckBoxComponent checked={getCheckedExS("Salones privados",restaurantInfo.extra_services)} text="Salones privados" onChange={handleMenu8}/>
                </div>
              
                </div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:'100%', marginBottom:'22px', justifyContent:'flex-start', marginTop:'0px', marginBottom:'30px'}}>
            <div style={{marginRight:'88px'}} >
                <CheckBoxComponent checked={getCheckedExS("Área de juegos para niños",restaurantInfo.extra_services)} text="Área de juegos para niños" onChange={handleMenu9}/>
                </div>

                <div style={{marginRight:'88px'}} >
                <CheckBoxComponent checked={getCheckedExS("Cena el 24 de diciembre",restaurantInfo.extra_services)} text="Cena el 24 de diciembre" onChange={handleMenu10}/>
                </div>

                <div style={{marginRight:'88px'}} >
                <CheckBoxComponent checked={getCheckedExS("Cena el 31 de diciembre",restaurantInfo.extra_services)} text="Cena el 31 de diciembre" onChange={handleMenu11}/>
                </div>

                <div style={{marginRight:'88px'}} >
                <CheckBoxComponent checked={getCheckedExS("Posibilidad de reservar el restaurante completo para eventos",restaurantInfo.extra_services)} text="Posibilidad de reservar el restaurante completo para eventos" onChange={handleMenu12}/>
                </div>
            </div>
            </div>
           
            <ModalComponent id="modalDescription" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                width: "111px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'63px'}}>
                                DESCRIPCIÓN
                            </label>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"14px",
                                    color:'gray'}}>
                                Descripción
                            </label>
                            <TextBoxComponent value={restaurantInfo.description.description} type="text" {...descriptionValidation} onChange={handleDescriptionChange}/> 
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link to={`/Restaurants/Details/${restaurantId.id}`} className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalDescription');
                                restaurantInfo.description.description = cancel;
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {putDescription();}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalDirection" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                width: "111px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'63px'}}>
                                DIRECCIÓN
                            </label>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"14px",
                                    color:'gray'}}>
                                Dirección
                            </label>
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleChange}
                                onSelect={handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div style={{width:'100%'}}>
                                    <input
                                    style={{width:'100%'}}
                                    {...getInputProps({
                                        placeholder: 'Buscar direccion ...',
                                        className: 'puerta21-select col-12 d-block location-search-input',
                                    })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                    {loading && <div>Buscando...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </div>
                                )}
                            </PlacesAutocomplete>
                            <label style={{color:'#f44336', fontSize:'0.75rem', fontFamily:'Roboto'}} hidden={directionValidation}>Selecciona un elemento de la lista</label>
                            <label style={{color:'#f44336', fontSize:'0.75rem', fontFamily:'Roboto'}} hidden={addresValidation}>Campo requerido</label>
                            {/* <TextBoxComponent value={restaurantInfo.address.address} type="text" {...directionValidation} onChange={handleDirectionChange}/>  */}
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link to={`/Restaurants/Details/${restaurantId.id}`} className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalDirection');
                                restaurantInfo.address.address = cancel;
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link>
                        <button className="btn btn-primary" type="button" onClick={() => { putDirection();}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalPhone" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                width: "111px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'63px'}}>
                                TELÉFONO
                            </label>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"14px",
                                    color:'gray'}}>
                                Teléfono
                            </label>
                            <TextBoxComponent value={restaurantInfo.description.phone} type="text"  {...phoneValidation} onChange={handlePhoneChange}/> 
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link to={`/Restaurants/Details/${restaurantId.id}`} className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalPhone');
                                restaurantInfo.description.phone = cancel;
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {putPhone();}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalChef" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                width: "111px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'63px'}}>
                                CHEF
                            </label>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"14px",
                                    color:'gray'}}>
                                Nombre del chef
                            </label>
                            <TextBoxComponent value={restaurantInfo.description.cheff_name} type="text" {...chefValidation} onChange={handleChefChange}/> 
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link to={`/Restaurants/Details/${restaurantId.id}`} className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalChef');
                                restaurantInfo.description.cheff_name = cancel;
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {putChef();}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalFood" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px 0px 40px", width:'100%'}}>
                            <label style={{
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'63px'}}>
                                TIPO DE COMIDA
                            </label>
                            {
                                kitchenCatalog.map(food =>(
                                    <div style={{display:'flex', width:'100%', marginBottom:'20px', justifyContent:'space-between'}}>
                                        <label style={{
                                                fontFamily:"Roboto-Light",
                                                fontSize:"24px",
                                                color:'gray'}}>
                                            {food.label}
                                        </label>
                                        <CheckBoxComponent checked={getCheckedKitchen(food.value)} onChange={(e) => {handlekitchen(e, food.value)}}/>
                                    </div>
                                ))
                            }
                        </div>
                        <div style={{display:"flex", marginTop:"21px"}}>
                        {/* <Link className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalFood')
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link> */}
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalFood');}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalPrice" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'63px'}}>
                                RANGO DE PRECIO
                            </label>
                            <Rating
                                onClick={setRatingComponent}
                                ratingValue={rating}
                                transition
                                size={60}
                                stars={5}
                                fillColor={'black'}
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                                </svg>
                            </Rating>
                            {/* <PriceComponent value={restaurantInfo.cost.name.length} size={50} onChange={setRatingComponent}/> */}
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link to={`/Restaurants/Details/${restaurantId.id}`} className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalPrice');
                                setRating(cancel);;
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {putCost();}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );
        
    const movile = () =>(
        <article>
            <a style={{position: "absolute", top:25, left:'5%' }} onClick={()=> window.location = "/Restaurants" }>
                <img width="24" height="24" src="/images/backicon.svg"/>
            </a>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '35%'
                         }}>
                RESTAURANTE
            </h5>
            <div className="div-tab-item" style={{marginTop:"15px"}}>
                <Link className="tabitem selected" to={`/Restaurants/Details/${restaurantId.id}`} style={{paddingBottom:'16px'}}>DESCRIPCIÓN</Link>
                <Link className="tabitem" to={`/Restaurants/Photographs/${restaurantId.id}`} style={{paddingBottom:'16px'}}>FOTOGRAFÍAS</Link>
                <Link className="tabitem" to={`/Restaurants/Capacity/${restaurantId.id}`} style={{paddingBottom:'16px'}}>CAPACIDAD</Link>
                <Link className="tabitem" to={`/Restaurants/Menu/${restaurantId.id}`} style={{paddingBottom:'16px'}}>MENÚ</Link>
                <Link className="tabitem" to={`/Restaurants/Hours/${restaurantId.id}`} style={{paddingBottom:'16px'}}>HORARIO</Link>
            </div>
            <div style={{display: "flex", alignItems: "center", padding: "30px 30px 0px 30px"}}>
                <label style={{fontFamily:"Commuters Sans SemiBold",
                    whiteSpace:'nowrap',
                    overflow:'hidden',
                    textOverflow:'ellipsis',
                    width:"500px",
                    height:"30px",
                    fontSize:"17px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    color: "black",
                    textAlign:"initial"
                    }}>
                    {restaurantInfo.name}
                </label>
            </div>
            <div style={{display: "flex", flexDirection:'column', alignItems: "center", padding: "30px"}}>
                <FieldEditable
                    text="Descripción"
                    style={{width:"100%", marginBottom:"30px"}}
                    value={restaurantInfo.description.description}
                    onClick={() => {ModalFunctions.ShowModal('modalDescription'); setCancel(restaurantInfo.description.description);}}/>
                <FieldEditable
                    text="Dirección"
                    style={{width:"100%", marginBottom:"30px"}}
                    value={restaurantInfo.address.address}
                    onClick={() => {ModalFunctions.ShowModal('modalDirection'); setCancel(restaurantInfo.address.address);}}/>
                <FieldEditable
                    text="Teléfono"
                    style={{width:"100%", marginBottom:"30px"}}
                    value={restaurantInfo.description.phone}
                    onClick={() => {ModalFunctions.ShowModal('modalPhone'); setCancel(restaurantInfo.description.phone);}}/>
                <FieldEditable
                    text="Nombre del chef"
                    style={{width:"100%", marginBottom:"30px"}}
                    value={restaurantInfo.description.cheff_name}
                    onClick={() => {ModalFunctions.ShowModal('modalChef'); setCancel(restaurantInfo.description.cheff_name);}}/>
                <FieldEditable
                    text="Tipo de comida"
                    style={{width:"100%", marginBottom:"30px"}}
                    value={kitchensOrigin}
                    onClick={() => {ModalFunctions.ShowModal('modalFood');}}/>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'100%', marginBottom:'22px', borderBottom:'1px solid', borderBottomColor:'rgba(200, 200, 200)'}}>
                    <label style={{fontFamily:"Roboto",
                                    fontSize:"14px",
                                    color:'#808080',
                                    marginBottom:'25px'}}>
                        Rango de precio
                    </label>
                    <RatingView
                        onClick={handleRating}
                        ratingValue={rating}
                        transition
                        size={25}
                        stars={5}
                        fillColor={'black'}
                        emptyColor={'white'}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                        </svg>
                    </RatingView>
                    {/* <PriceComponent style={{width:'200px', height:'65px'}} value={restaurantInfo.cost.name.length} edit={false} size={30}/> */}
                    <button className="btn btn-primary" style={{position:'relative', 
                                                                left: 'calc(100% - 84px)', 
                                                                bottom: 25, 
                                                                width:"80px", 
                                                                height:"24px", 
                                                                fontSize:"12px", 
                                                                textAlign:"center", 
                                                                paddingBottom:"0px",
                                                                paddingTop:"0px",
                                                                paddingLeft:"0px",
                                                                paddingRight:"0px", 
                                                                boxShadow:"none"}}
                                                        onClick={() => {ModalFunctions.ShowModal('modalPrice'); setCancel(rating);}}>
                        EDITAR
                    </button>
                </div>
                <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', alignItems:'flex-start', width:'100%', marginBottom:'22px', justifyContent:'space-between'}}>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Valet parking", restaurantInfo.extra_services)} text="Valet parking" onChange={handleValet}/>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Menú vegano",restaurantInfo.extra_services)} text="Menú vegano" onChange={handleMenu}/>
                    <CheckBoxComponent checked={getCheckedExS("Acceso para silla de ruedas",restaurantInfo.extra_services)} text="Acceso para silla de ruedas" onChange={handleAccess}/>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Miembros de Aderes",restaurantInfo.extra_services)} text="Miembros de Aderes" onChange={handleMenu2}/>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Certificado Ministerio de Turismo",restaurantInfo.extra_services)} text="Certificado Ministerio de Turismo" onChange={handleMenu3}/>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Admiten mascotas",restaurantInfo.extra_services)} text="Admiten mascotas" onChange={handleMenu4}/>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Terraza",restaurantInfo.extra_services)} text="Terraza" onChange={handleMenu5}/>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Área para fumar",restaurantInfo.extra_services)} text="Área para fumar" onChange={handleMenu6}/>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Parqueo cerca",restaurantInfo.extra_services)} text="Parqueo cerca" onChange={handleMenu7}/>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Salones privados",restaurantInfo.extra_services)} text="Salones privados" onChange={handleMenu8}/>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Área de juegos para niños",restaurantInfo.extra_services)} text="Área de juegos para niños" onChange={handleMenu9}/>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Cena el 24 de diciembre",restaurantInfo.extra_services)} text="Cena el 24 de diciembre" onChange={handleMenu10}/>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Cena el 31 de diciembre",restaurantInfo.extra_services)} text="Cena el 31 de diciembre" onChange={handleMenu11}/>
                    <CheckBoxComponent style={{marginBottom:"20px"}} checked={getCheckedExS("Posibilidad de reservar el restaurante completo para eventos",restaurantInfo.extra_services)} text="Posibilidad de reservar el restaurante completo para eventos" onChange={handleMenu12}/>


                </div>
            </div>
    
            <ModalComponent id="modalDescription" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                width: "111px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'63px'}}>
                                DESCRIPCIÓN
                            </label>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"14px",
                                    color:'gray'}}>
                                Descripción
                            </label>
                            <TextBoxComponent value={restaurantInfo.description.description} type="text" {...descriptionValidation} onChange={handleDescriptionChange}/> 
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link to={`/Restaurants/Details/${restaurantId.id}`} className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalDescription');
                                restaurantInfo.description.description = cancel;
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {putDescription();}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalDirection" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                width: "111px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'63px'}}>
                                DIRECCIÓN
                            </label>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"14px",
                                    color:'gray'}}>
                                Dirección
                            </label>
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleChange}
                                onSelect={handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div style={{width:'100%'}}>
                                    <input
                                    style={{width:'100%'}}
                                    {...getInputProps({
                                        placeholder: 'Buscar direccion ...',
                                        className: 'puerta21-select col-12 d-block location-search-input',
                                    })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                    {loading && <div>Buscando...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </div>
                                )}
                            </PlacesAutocomplete>
                            <label style={{color:'#f44336', fontSize:'0.75rem', fontFamily:'Roboto'}} hidden={directionValidation}>Selecciona un elemento de la lista</label>
                            <label style={{color:'#f44336', fontSize:'0.75rem', fontFamily:'Roboto'}} hidden={addresValidation}>Campo requerido</label>
                            {/* <TextBoxComponent value={restaurantInfo.address.address} type="text" {...directionValidation} onChange={handleDirectionChange}/>  */}
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link to={`/Restaurants/Details/${restaurantId.id}`} className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalDirection');
                                restaurantInfo.address.address = cancel;
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {putDirection();}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalPhone" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                width: "111px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'63px'}}>
                                TELÉFONO
                            </label>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"14px",
                                    color:'gray'}}>
                                Teléfono
                            </label>
                            <TextBoxComponent value={restaurantInfo.description.phone} type="text"  {...phoneValidation} onChange={handlePhoneChange}/> 
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link to={`/Restaurants/Details/${restaurantId.id}`} className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalPhone');
                                restaurantInfo.description.phone = cancel;
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {putPhone();}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalChef" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                width: "111px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'63px'}}>
                                CHEF
                            </label>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"14px",
                                    color:'gray'}}>
                                Nombre del chef
                            </label>
                            <TextBoxComponent value={restaurantInfo.description.cheff_name} type="text" {...chefValidation} onChange={handleChefChange}/> 
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link to={`/Restaurants/Details/${restaurantId.id}`} className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalChef');
                                restaurantInfo.description.cheff_name = cancel;
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {putChef();}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalFood" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px 0px 40px", width:'100%'}}>
                            <label style={{
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'63px'}}>
                                TIPO DE COMIDA
                            </label>
                            {
                                kitchenCatalog.map(food =>(
                                    <div style={{display:'flex', width:'100%', marginBottom:'20px', justifyContent:'space-between'}}>
                                        <label style={{
                                                fontFamily:"Roboto-Light",
                                                fontSize:"24px",
                                                color:'gray'}}>
                                            {food.label}
                                        </label>
                                        <CheckBoxComponent checked={getCheckedKitchen(food.value)} onChange={(e) => {handlekitchen(e, food.value)}}/>
                                    </div>
                                ))
                            }
                        </div>
                        <div style={{display:"flex", marginTop:"21px"}}>
                        {/* <Link className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalFood')
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link> */}
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalFood');}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalPrice" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'63px'}}>
                                RANGO DE PRECIO
                            </label>
                            {/* <PriceComponent value={restaurantInfo.cost.name.length} style={{height:'50px'}} size={50} onChange={setRatingComponent}/> */}
                            <Rating
                                onClick={setRatingComponent}
                                ratingValue={rating}
                                transition
                                size={60}
                                stars={5}
                                fillColor={'black'}
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                                </svg>
                            </Rating>
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link to={`/Restaurants/Details/${restaurantId.id}`} className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalPrice');
                                setRating(cancel);
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {putCost();}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
    
        </article>    
    );

    return (
        isDesktopOrLaptop ? desktop() : movile()
    )
}

export default RestaurantDetails
